import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        olive: Palette['primary'];
    }

    interface PaletteOptions {
        olive?: PaletteOptions['primary'];
    }
}




export const qprtheme = createTheme({
    custom: {
        name: 'qpr',
        academyName: 'QPR Academy',
        academyCommonName: 'QPR Academy',
        academyCommonShortName: 'QPR',
        isWhiteLabelled: true,
        stripe: {
            colorPrimary: "#012448", //this.palette.secondary.main,
            colorBackground: "inherit", //this.custom.isWhiteLabelled ? this.custom.mainBackgroundColor : this.custom.mainBackgroundColor,
            colorText: "#012448", //this.custom.isWhiteLabelled ? this.custom.stripePaymentText : this.palette.text.primary,
            colorDanger: "#FF6E6E", //this.palette.warning.main,
            colorWarning: "#FF6E6E", //this.palette.warning.main,
            fontFamily: [
                'Outfit',
                'sans-serif',
            ].join(','), //this.typography.fontFamily,
            labelFontFamily: [
                'Outfit',
                'sans-serif',
            ].join(','), //this.typography.fontFamily,
            fontSizeBase: "16px",
            fontWeightNormal: "400",
            spacingUnit: "5px",
            borderRadius: "16px",
            borderColor: "#0054A4",
        },
        wl: {
            supportEmail: "support@qpr.viaacademy.com",
            termsURI: "https://www.viaacademy.com/qpr/terms",
            privacyURI: "https://www.viaacademy.com/qpr/privacy",
            faqURI: "https://www.viaacademy.com/qpr/faq",
            globalBorderRadius: "8px",
            splashPageButtonBgColor: "#0054A4",
            signIn: {
                buttonBackgroundColor: "#0F4C8B",
                buttonBorderColor: "#0F4C8B",
            },
            accountPages: {
                accountManagementURI: "https://login.qpr.co.uk/Dashboard/Profile"
            },
            bottomCTA: {
                bgColor: "#FFFFFF",
                buttonBackgroundColor: "#0054A4",
                buttonColor: "#FFFFFF"
            },
            purchaseStepHeader: {
                backgroundColor: '#FFFFFF',
                color: "#0054A4",
                liveProgressTextColor: "#000000",
                progressBackgroundColor: "#999999"
            },
            improvementStep: {
                backgroundColor: '#F1F1F1',
                color: '#0054A4'
            },
            bottomAppTabs: {
                backgroundColor: "#0054A4",
                color: "#012448",
                selectedColor: "#F1C228",
                borderTop: "#0054A4"
            },
            surveyPoll: {
                optionColor: "#0054A4",
                voteStatusColor: "#666666",
                chosenColor: "#7ABEFF",
            },
            aiCoach: {
                coachAvatarUri: "/ai_coach/ffc_coach_avatar.png",
                coachBubbleBackgroundColor: "#0054A4",
                coachBubbleTextColor: "#F1F1F1",
                coachBubbleChoiceTextColor: "#0054A4",
                playerAvatarUri: "/ai_coach/ffc_coach_avatar.png",
                playerBubbleBackgroundColor: "#FFFFFF",
                playerBubbleTextColor: "#012448",
                playerQuestionChoiceDelimiterColor: "#EBEBEB"
            }
        },
        headerBrandTitleColor: '#F1F1F1',
        headerBrandTitleText: 'Virtual Academy',
        headerBrandTitleBackgroundColor: '#0054A4',
        brandTitleLogo: "/club_logos/qpr_logo.png",
        brandCoverPhoto: "/wl/qpr_gateway_cover.png",
        mainBackgroundColor: "#FFFFFF",
        contentFeedBackgroundColor: "#F1F1F1",
        stripePaymentText: '#0054A4',
        uploadBoxColor: "#F1F1F1",
        communityPostTextColor: "#0054A4",
        communityLikertTextColor: "#012448",
        communityPollButtonColor: "#0054A4",
        darkerPrimaryColor: "#012448",
        subscriptionChoiceBorderColor: "#0F4C8B",
        monthlyProSubscriptionSKU: "PS-QPR-M",
        yearlyProSubscriptionSKU: "PS-QPR-Y",
        coachFeedbackPromoPoster: "/club_posters/QPR_feedback_poster.png",
        coachFeedbackVideoSampleLink: "https://via.kz-cdn.net/static/samples/qpr_sample.mp4",
        coachFeedbackVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/qpr_sample_thumbnail.jpg",
        submissionVideoSampleLink: "https://via.kz-cdn.net/static/samples/video_guidelines.mp4",
        submissionVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/video_guidelines_thumbnail.jpg",
        newsFeedIndexSlug: "qpr-news-feed-index",
        trainingFeedIndexSlug: "qpr-training-videos-index",
        proVideoLabelColor: "#C98B07",
        freeVideoLabelColor: "#012448",
        newButtonColor: "#B52C23",
        matchAnalysisBackground: "#FDFFE3",
        highlightAnalysisBackground: "#E3FFFC",
        whatYouGetBackground: "#B7FFE2",
        testimonialBackground: "#FFDAB7",
    },
    typography: {
        fontFamily: [
            'Outfit',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: "none"
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0054A4',
            contrastText: '#081111',
        },
        secondary: {
            main: '#012448',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        error: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        info: {
            main: '#2196f3',
            contrastText: '#DAB870',
        },
        text: {
            primary: '#0054A4',
            secondary: '#999999',
        },
        grey: {
            100: '#F1F1F1',
            200: '#E8E8E8',
            300: '#D2D2D2',
            500: '#AAAAAA',
            600: '#A9A9A9',
            700: '#616161',
            800: '#414141',
            900: '#121212'
        },
        common: {
            black: '#081111'
        },
        background: {
            paper: '#F1F1F1'
        },
        divider: "#222222",
        olive: {
            main: '#162121',
        }
    },
    // components: {
    //     MuiTypography: {
    //         defaultProps: {
    //             variantMapping: {
    //                 h1: 'h2',
    //                 h2: 'h2',
    //                 h3: 'h2',
    //                 h4: 'h2',
    //                 h5: 'h2',
    //                 h6: 'h2',
    //                 subtitle1: 'h2',
    //                 subtitle2: 'h2',
    //                 body1: 'span',
    //                 body2: 'span',
    //             },
    //         },
    //     },
    // },
})
