export const routes = {
    all: "*",
    signUp: "/sign-up",
    signIn: "/sign-in",
    signOut: "/sign-out",
    appSignIn: "/app-sign-in",
    home: "/",
    u13: "/u13-info",
    purchase: "/purchase",
    accountManagement: "/my-account/home",
    accountSubscriptions: "/my-account/subscriptions",
    subscribe: "/subscribe/:plan/:step",
    subscriptionPurchaseConfirmation: "/subscription-purchase-confirmation/:subId/",
    orderCreated: "/order-created/:order",
    monthlyDrawCreated: "/order-created/monthly-draw",
    orderSubmitted: "/order-submitted",
    insights: "/insights",
    academyPost: "/posts/:postId",
    directSectionAccess: "/get/:pagePath",
    directPageAccess: "/get/:pagePath/:pageId",
    trainingVideo: "/train/:pageId",
    coachFeedback: "/feedback",
    train: "/training",
    referrer: "/r/:orgName",
    whiteLabel: "/wl/:orgName"
}
