import React from "react"
import {Box, Button, styled} from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import FeedIcon from '@mui/icons-material/Feed';
import MenuOutlined from '@mui/icons-material/MenuOutlined';
import RecordVoiceOver from '@mui/icons-material/RecordVoiceOver';
import RecordVoiceOverOutlined from '@mui/icons-material/RecordVoiceOverOutlined';
import {useNavigate} from "react-router-dom";
import {routes} from "../constants/routes";
import {useTheme} from "@mui/material/styles";

const StyledBottomAppTabs = styled(Box)(({theme}) => ({
    flex: "0 0 auto",
    width: "100%",
    // fontSize: "18px",
    // color: theme.palette.text.primary,
    backgroundColor: theme.custom.wl.bottomAppTabs.backgroundColor,
    position: "sticky",
    bottom: 0,
    left: 0,
    zIndex: 25,
    height: "60px",
    borderTopStyle: "solid",
    borderTopWidth: 0.5,
    borderTopColor: theme.custom.wl.bottomAppTabs.borderTop,
    paddingBottom: "12px",
}));

const StyledAppTabs = styled('div')(() => ({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    width: "100%",
}));

const StyledAppTab = styled(Button)(({theme}) => ({
    color: theme.custom.wl.bottomAppTabs.color,
    height: "100%",
    display: "flex",
    flexDirection: "column",
}));


export const BottomAppTabs = (): React.ReactElement => {
    const navigate = useNavigate()
    const theme = useTheme()

    return(
        <StyledBottomAppTabs>
            <StyledAppTabs>
                <StyledAppTab onClick={()=> navigate(routes.insights)}>{window.location.pathname === routes.insights || window.location.pathname === routes.home || window.location.pathname.startsWith('/posts/') ? <><FeedIcon style={{ color: theme.custom.wl.bottomAppTabs.selectedColor }} /><span style={{marginTop: "-6px", color: theme.custom.wl.bottomAppTabs.selectedColor}}>Insights</span></> : <><FeedOutlinedIcon style={{ color: "white", opacity: 0.7 }}/><span style={{marginTop: "-6px", color: 'white', opacity: 0.7}}>Insights</span></>}</StyledAppTab>
                <StyledAppTab onClick={()=> navigate(routes.coachFeedback)}>{window.location.pathname === routes.coachFeedback || window.location.pathname.startsWith('/purchase') ? <><PlayCircleIcon style={{ color: theme.custom.wl.bottomAppTabs.selectedColor }}/><span style={{marginTop: "-6px", color: theme.custom.wl.bottomAppTabs.selectedColor}}>Feedback</span></> : <><PlayCircleOutlineIcon style={{ color: "white", opacity: 0.7 }}/><span style={{marginTop: "-6px", color: "white", opacity: 0.7}}>Feedback</span></>}</StyledAppTab>
                <StyledAppTab onClick={()=> navigate(routes.train)}>{window.location.pathname === routes.train || window.location.pathname.startsWith('/train/') ? <><RecordVoiceOver style={{ color: theme.custom.wl.bottomAppTabs.selectedColor }}/><span style={{marginTop: "-6px", color: theme.custom.wl.bottomAppTabs.selectedColor}}>AI Coach</span></> : <><RecordVoiceOverOutlined style={{ color: "white", opacity: 0.7 }}/><span style={{marginTop: "-6px", color: "white", opacity: 0.7}}>AI Coach</span></>}</StyledAppTab>
                <StyledAppTab onClick={()=> navigate(routes.accountManagement)}>{window.location.pathname === routes.accountManagement || window.location.pathname.startsWith('/my-account/home') ? <><MenuOutlined style={{ color: theme.custom.wl.bottomAppTabs.selectedColor }}/><span style={{marginTop: "-6px", color: theme.custom.wl.bottomAppTabs.selectedColor}}>More</span></> : <><MenuOutlined style={{ color: "white", opacity: 0.7 }}/><span style={{marginTop: "-6px", color: "white", opacity: 0.7}}>More</span></>}</StyledAppTab>
            </StyledAppTabs>
        </StyledBottomAppTabs>
    )
}
