import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, styled, Typography, useTheme} from "@mui/material";
import {ScreenLayout} from "../../shared/ScreenLayout";
import {
    fetchProcessingFeedbackStatuses,
    initialPage as processingFeedbackInitialPage,
    pageSize as processingFeedbackPageSize,
    ProcessingFeedbackSection
} from "./components/sections/ProcessingFeedbackSection";
import {
    fetchProcessedFeedbackStatuses,
    initialPage as processedFeedbackInitialPage,
    pageSize as processedFeedbackPageSize,
    ProcessedFeedbackSection
} from "./components/sections/ProcessedFeedbackSection";
import {routes} from "../../constants/routes";
import {MonthlyDrawSection} from "./components/sections/MonthlyDrawSection";
import {LightDivider} from "../../shared/styles";

import {Analytics} from "../../lib/analytics";
import {coachFeedbackServiceApi} from "../../services/coach-feedback-service";
import {Loader} from "../../shared/Loader";
import {setAllowUserToRedirect} from "../../features/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {categoriesServiceApi} from "../../services/categories-service";
import {SoldOut} from "../feedback/components/SoldOut";


const StyledTitle = styled(Typography)(() => ({
    fontSize: "32px",
    fontWeight: "700",
}));

export const Home = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allowToRedirect = useAppSelector<boolean>((state: RootState) => state.user.allowToRedirect);
    const {data: processingFeedbackData, isFetching: isProcessingFeedbackFetching} = coachFeedbackServiceApi.useGetCoachFeedbackQuery({page: processingFeedbackInitialPage, pageSize: processingFeedbackPageSize, status: fetchProcessingFeedbackStatuses});
    const {data: monthlyDrawData, isFetching: isSubmittedMonthlyDrawFetching} = coachFeedbackServiceApi.useGetSubmittedBallotQuery("");
    const {data: processedFeedbackData, isFetching: isProcessedFeedbackFetching} = coachFeedbackServiceApi.useGetCoachFeedbackQuery({page: processedFeedbackInitialPage, pageSize: processedFeedbackPageSize, status: fetchProcessedFeedbackStatuses});
    const loading = isProcessingFeedbackFetching || isSubmittedMonthlyDrawFetching || isProcessedFeedbackFetching;
    const [showFeedbackWall, setShowFeedbackWall] = useState<boolean>(false);

    const {isLoading: academiesLoading, data: academies, isFetching: academiesFetching} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true });
    const getAvailableAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft > 0) ?? [], [academies]);
    // const CFPrice = getAvailableAcademies().length ? " for " + getAvailableAcademies()[0].currency_symbol + getAvailableAcademies()[0].price : ""

    useEffect(() => {
        Analytics.homeView();
    }, []);

    useEffect(() => {
        if (!allowToRedirect || loading) {
            return;
        }

        if (!monthlyDrawData && !processingFeedbackData?.results.length && !processedFeedbackData?.results.length) {
            dispatch(setAllowUserToRedirect(false));
            navigate(routes.purchase);
        }
    }, [allowToRedirect, loading, monthlyDrawData, processingFeedbackData, processedFeedbackData, navigate, dispatch]);

    // const getCoachFeedback = () => {
    //     Analytics.getCoachFeedbackButtonClick();
    //     startPurchase();
    // }
    return (
        <>
            {loading && <Loader/>}
            <Box sx={{display: loading ? "none" : "flex", flex: 1}}>
                <ScreenLayout
                    title={<StyledTitle color={"secondary"}>Feedback</StyledTitle>}
                    ctaTitle='Get Academy Feedback'
                    onAction={() => setShowFeedbackWall(true)}
                    displayCta={!showFeedbackWall}
                    ctaDisabled={academiesLoading || academiesFetching || !getAvailableAcademies().length}
                    ctaUnderSection={<>
                        {(academiesFetching || academiesLoading)?
                            null
                            :
                            <>
                                 {!getAvailableAcademies().length && <SoldOut/>}
                            </>
                        }
                    </>}
                    contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? "#FFFFFF" : undefined}
                    displayFeedbackGateway={showFeedbackWall}
                    hideFeedbackWall={() => setShowFeedbackWall(false)}
                >
                    <>
                        <ProcessingFeedbackSection/>
                        {!theme.custom.isWhiteLabelled && 
                        <>
                        <LightDivider sx={{m: "30px 0"}} />
                        <MonthlyDrawSection/>
                        <LightDivider sx={{m: "30px 0"}} />
                        </>}
                        <ProcessedFeedbackSection/>
                    </>
                </ScreenLayout>
            </Box>
        </>
    )
}
