import React, {useEffect} from 'react'
import {LoaderWithWatchdog} from "../../shared/LoaderWithWatchdog";
import {useNavigate, useParams} from "react-router-dom";
import {
    checkIsAnonymousUser, getAttemptedPage, getGotoUrl,
    setAttemptedPage, setCurrentPostPath,
    setCurrentSiteSection,
    setRedirectAfterLogin,
    setSignupMode,
    SiteSections
} from "../../util/storage";
import {
    checkForHydrationTokens,
    clearPreventEmailSignInTimeOut,
} from "../../services/cognito";
import {createAnonAccountThunk} from "../../features/user/userSlice";
import {useAppDispatch} from "../../redux/hooks";
import {routes} from "../../constants/routes";
import {useTheme} from "@mui/material";

export const DirectPageAccess = (): React.ReactElement => {
    const params = useParams();
    const pagePath = !!params.pagePath ?
        !!params.pageId ?
            params.pagePath + "/" + params.pageId
            : params.pagePath
        : "/"
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()


    console.log('/' + pagePath)
    setAttemptedPage('/' + pagePath)


    useEffect(() => {
        const AnonLoginClickHandler = (goTo: SiteSections, goToPostPath: string = "") => {
            if(goTo === "PostItem"){
                console.log('train goto', goToPostPath)
                setCurrentPostPath("/" + goToPostPath)
            }else{
                setCurrentSiteSection(goTo)
            }
            setSignupMode(0, false, true)
            clearPreventEmailSignInTimeOut()
            dispatch(createAnonAccountThunk())
        }



        const hasRehydrationTokens = checkForHydrationTokens()
        console.log("checkHT: ", hasRehydrationTokens)
        console.log("pagePath: ", pagePath)
        if(!theme.custom.isWhiteLabelled || hasRehydrationTokens){
            navigate(
                getAttemptedPage() === "/" ? routes.home
                : getGotoUrl(true)
            )
        }
        if(checkIsAnonymousUser()){
            console.log("isAnon on Directr")
            navigate(
                getAttemptedPage() === "/" ? routes.home
                    : getGotoUrl(true)
            )
        }else{
            if(pagePath.toUpperCase() === 'FEEDBACK') {
                setRedirectAfterLogin()
                AnonLoginClickHandler("Feedback")
            }else if(pagePath.toUpperCase() === 'TRAINING'){
                setRedirectAfterLogin()
                AnonLoginClickHandler("Training")
            }else if(pagePath.toUpperCase().includes('TRAIN')){
                AnonLoginClickHandler("PostItem", pagePath)
            }else if(pagePath.toUpperCase().includes('POSTS')){
                setRedirectAfterLogin()
                console.log('train newPath', pagePath)
                AnonLoginClickHandler("PostItem", pagePath)
            }else if(pagePath.toUpperCase() === 'INSIGHTS'){
                setRedirectAfterLogin()
                AnonLoginClickHandler("Insights")
            }else{
                navigate(
                    getAttemptedPage() === "/" ? routes.home
                        : getGotoUrl()
                )
            }
        }
    }, [navigate,pagePath, dispatch, theme.custom.isWhiteLabelled]);



    return (<LoaderWithWatchdog/>)
}