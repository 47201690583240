import {
    IMonthlyDrawResponse,
    IMonthlyDraw,
    ICoachFeedbackItemResponse,
    ICoachFeedbackResult
} from "../../types/coachFeedback";

export const transformMonthlyDrawResponse = (
    {
        display_name,
        created_at,
        others_in_video,
        identification_notes,
        order_name,
        order_age,
        order_position,
        improvement_areas,
        is_winner,
        feedback_request,
        media
    }: IMonthlyDrawResponse
): IMonthlyDraw => {
    return {
        displayName: display_name,
        createdAt: created_at,
        othersInVideo: others_in_video,
        identificationNotes: identification_notes,
        orderName: order_name ? order_name : "",
        orderAge: order_age ? order_age : undefined,
        orderPosition: order_position ? order_position : undefined,
        improvementAreas: improvement_areas.map(({detail}) => detail),
        isWinner: is_winner,
        feedbackRequest: feedback_request ? { organisationName: feedback_request.organisation.name } : null,
        media: {
            video: {
                mimeType: media.video.mime_type,
                key: media.video.key,
                url: media.video.url,
            }
        }
    }
}

export const transformCoachFeedbackResponse = (
    {
        uuid,
        created_at,
        display_name,
        order,
        organisation,
        identification_notes,
        others_in_video,
        order_name,
        order_age,
        order_position,
        improvement_areas,
        status,
        video,
        feedback,
    }: ICoachFeedbackItemResponse
): ICoachFeedbackResult => {
    return {
        id: uuid,
        createdAt: created_at,
        displayName: display_name,
        order: order ? {
            id: order.id,
            createdAt: order.created_at,
        } : null,
        organisationId: organisation.uuid,
        identificationNotes: identification_notes,
        othersInVideo: others_in_video,
        orderName: order_name ? order_name : "",
        orderAge: order_age,
        orderPosition: order_position ? order_position : "",
        improvementAreas: improvement_areas.map(({detail}) => detail),
        status,
        video: video ? {
            key: video.video.key,
            url: video.video.url,
        } : null,
        feedback: feedback ? {
            key: feedback.video?.key ?? "",
            url: feedback.video?.url ?? "",
            createdDate: feedback.sign_off_date,
        } : null,
        matchAnalysisReport: feedback?.match_analysis_report ? {
            url: feedback.match_analysis_report.url,
            downloadUrl: feedback.match_analysis_report.download_url
        } : null
    }
}
