import React, {useEffect} from "react";
import {Routes, Route, Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {AppConfig} from "./config";
import "./viaStyles.scss"
import "./skins/ffc/ffcStyles.scss"
import "./skins/qpr/qprStyles.scss"
import "./skins/pufc/pufcStyles.scss"
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {RootState} from "./redux/store";
import {LandingDownload} from "./views/landing/LandingDownload";
import {LoginForm} from './views/login/LoginForm';
import {getOrganisationIdThunk, getUserProfileThunk, userLogout, ViaUserProfile} from "./features/user/userSlice";
import {ViaContainer} from "./features/layout/ViaContainer";
import {PurchaseFlow} from "./features/purchase/PurchaseFlow";
import {OrderCreated} from "./features/purchase/order-created/OrderCreated";
import {HeaderMode, isAccountsSection} from "./shared/Header";
import {Footer} from "./shared/Footer";
import {PermissionRequired} from "./features/permissions/PermissionRequired";
import {getConsentThunk, IConsent} from "./features/consent/consentSlice";
import {checkForHydrationTokens, getIdToken, rehydrateSession, signOut, userInitiatedSignOut} from "./services/cognito";
import {routes} from "./constants/routes";
import {useResetApiState} from "./hooks/useResetApiState";
import {purchaseStepDataStorageKey} from "./constants/purchase";
import {AcceptTerms} from "./views/home/components/guards/AcceptTerms";
import Logger from "./util/logger";
import {checkIsAnonymousUser, getItem, removeItem, setAttemptedPage, setItem} from "./util/storage";
import {OrderSubmitted} from "./features/purchase/order-created/OrderSubmitted";
import {SwitchUser} from "./views/home/components/guards/SwitchUser";
import {AppLogin} from "./views/appLogin/AppLogin";
import {Analytics} from "./lib/analytics";
import {useHash} from "./hooks/useHash";
import {forceLogoutKey} from "./constants/urlParameters";
import {SignUp} from "./views/signup/SignUp";
import {PartnerReferred} from "./views/referrer/PartnerReferred";
import {WhiteLabelled} from "./views/whiteLabel/WhiteLabelled";
import {styled, useTheme} from "@mui/material";
import MainComponent from "./MainComponent";
// import DynamicFavicon from "./DynamicFavicon";
import {AcademyUpdates} from "./views/academyUpdates/AcademyUpdates";
import {Train} from "./views/train/Train";
import {BottomAppTabs} from "./shared/BottomAppTabs";
import {AcademyPost} from "./views/academyUpdates/AcademyPost";
import {TrainingPost} from "./views/train/TrainingPost";
import {Subscribe} from "./views/subscribe/Subscribe";
import {StripeProvider} from "./shared/StripeProvider";
import {stripeOptions} from "./constants/stripe";
import {AccountManagementPage} from "./views/accountManagement/AccountManagementPage";
import {AccountSubscriptions} from "./views/accountManagement/AccountSubscriptions";
import {getUserSubscriptionsThunk} from "./features/subscriptions/subscriptionsSlice";
import {FeedbackPageSelector} from "./views/feedback/FeedbackPageSelector";
import {SubscriptionConfirmation} from "./views/subscribe/SubscriptionConfirmation";
import {U13Message} from "./views/gateway/U13Message";

// import { Helmet } from 'react-helmet';
import {getOrgIdFromTheme} from "./lib/asyncUtils";
import {LogoutFromApp} from "./views/logout/LogoutFromApp";
import {GuestGateway} from "./views/gateway/GuestGateway";
import {GuestGatewayComponent} from "./views/gateway/GuestGatewayComponent";
import {LoaderWithWatchdog} from "./shared/LoaderWithWatchdog";
import {setNavigate} from "./services/navigationService";
import {DirectPageAccess} from "./views/gateway/DirectPageAccess";

const StyledMain = styled(MainComponent)<React.PropsWithChildren<{}>>(({ theme }) => (
    ( !!theme.custom.mainBackgroundColor && {backgroundColor: theme.custom.mainBackgroundColor} )
));

setAttemptedPage(document.location.pathname)

export default function App() {
    const theme = useTheme()
    const dispatch = useAppDispatch();
    const [resetApiState] = useResetApiState();
    const navigate = useNavigate();
    const hashForceLogout = useHash().get(forceLogoutKey);
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const profileFetched = useAppSelector<boolean>((state: RootState) => state.user.profileFetched);
    const consentData = useAppSelector<IConsent|null>((state: RootState) => state.consentSlice.consent.data);
    const userHasAccessToApp = !profile?.user.is_minor || (profile?.user.is_minor && consentData?.consented);
    const sessionStorageToken = sessionStorage.getItem("idToken");
    const isAnonymousUser = checkIsAnonymousUser()

    // Rehydrate the session on page load
    useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);

    useEffect(() => {
        Logger.init(AppConfig.ENVIRONMENT, AppConfig.COGNITO.OAUTH_LOGIN_REDIRECT);
    }, []);
    useEffect(() => {
        const getProfile = async () => {
            setItem("organisation_id", await getOrgIdFromTheme(theme))
            setItem("news_index_slug", theme.custom.newsFeedIndexSlug)
            setItem("training_index_slug", theme.custom.trainingFeedIndexSlug)
            if (!profile && (await getIdToken() || checkForHydrationTokens() )){
                rehydrateSession();
                dispatch(getUserProfileThunk());
            }
        }
        getProfile();
    }, [profile, dispatch,theme]);

    useEffect(()=>{
        dispatch(getOrganisationIdThunk(theme.custom.name.toUpperCase()))
        if(profile){
            dispatch(getUserSubscriptionsThunk())
        }
    },[dispatch, theme, profile])
    useEffect(() => {
        if (profile) {
            Analytics.setUserData(profile);
        }
    }, [profile]);
    useEffect(() => {
        if (profile && profile.user.is_minor && !consentData) {
            dispatch(getConsentThunk());
        }

    }, [consentData, profile, dispatch]);
    useEffect(() => {
        const forceLogOut = async () => {
            await signOut();
            await userInitiatedSignOut();
            dispatch(userLogout());
            navigate(routes.signIn);
        }
        if (hashForceLogout) {
            forceLogOut();
        }
    }, [hashForceLogout, dispatch, navigate]);
    useEffect(() => {
        if (!sessionStorageToken) {
            resetApiState();
            removeItem(purchaseStepDataStorageKey);
        }
    }, [resetApiState, sessionStorageToken]);

    if (sessionStorageToken && !(profileFetched || isAnonymousUser)) {
        return (
            <Routes>
                <Route path={routes.all} element={<LoaderWithWatchdog/>}/>
            </Routes>
        )
    }

    return (
        <div className="App">
            {/*<DynamicFavicon />*/}
            {/*<Helmet>*/}
            {/*    <title>{theme.custom.isWhiteLabelled ? `${theme.custom.name.toUpperCase()} Virtual Academy` : 'VIA Coach Feedback'}</title>*/}
            {/*</Helmet>*/}
            <Routes>
                <Route path={routes.directPageAccess} element={<DirectPageAccess/>}/>
                <Route path={routes.directSectionAccess} element={<DirectPageAccess/>}/>
                <Route path={routes.signOut} element={<LogoutFromApp/>}/>
                <Route element={<SwitchUser/>}>
                    {profile === undefined && !isAnonymousUser &&
                        <>
                            <Route path={routes.home}
                                   element={<AppLayout showHeader={false} hideBottomTabs={true}>
                                       {theme.custom.isWhiteLabelled ?
                                           <GuestGateway/>
                                           :
                                           <LandingDownload/>
                                       }
                                    </AppLayout>}
                            />
                            <Route path={routes.signIn}
                                   element={<AppLayout showHeader={false} hideBottomTabs={true}><LoginForm/></AppLayout>}/>
                            <Route path={routes.signUp}
                                   element={<AppLayout showHeader={theme.custom.isWhiteLabelled} hideBottomTabs={true}><SignUp/></AppLayout>}/>
                            <Route path={routes.appSignIn} element={<AppLayout showHeader={false} showFooter={false} hideBottomTabs={true}><AppLogin/></AppLayout>}/>
                        </>
                    }
                    <Route element={<AcceptTerms/>}>
                        {((profile && userHasAccessToApp) || isAnonymousUser) &&
                            <>
                                <Route path={routes.u13}
                                       element={<AppLayout showHeader={true} showFooter={false}><U13Message/></AppLayout>}/>
                                <Route path={routes.home}
                                       element={<AppLayout showHeader={false} showFooter={false} hideBottomTabs={true}>{theme.custom.isWhiteLabelled
                                           ? <GuestGatewayComponent
                                               onInsightClick={() => {
                                                   Analytics.homeInsightsButtonClick();
                                                   navigate(routes.insights)
                                               }}
                                               onFeedbackClick={()=> {
                                                   Analytics.homeFeedbackButtonClick();
                                                   navigate(routes.coachFeedback)
                                               }}
                                               onTrainingClick={()=>{
                                                   Analytics.homeTrainingButtonClick();
                                                   navigate(routes.train)
                                               }}
                                               chosen={null} />
                                           : <FeedbackPageSelector/>}</AppLayout>}/>
                                <Route path={routes.academyPost}
                                       element={<AppLayout showHeader={false} showFooter={false}><AcademyPost/></AppLayout>}/>
                                <Route path={routes.insights}
                                       element={<AppLayout showHeader={theme.custom.isWhiteLabelled} showFooter={false}><AcademyUpdates/></AppLayout>}/>
                                <Route path={routes.coachFeedback}
                                       element={<AppLayout showHeader={theme.custom.isWhiteLabelled} showFooter={false}><FeedbackPageSelector/></AppLayout>}/>
                                <Route path={routes.subscriptionPurchaseConfirmation}
                                       element={<AppLayout showHeader={true} showFooter={false}><SubscriptionConfirmation/></AppLayout>}/>
                                <Route path={routes.trainingVideo}
                                       element={<AppLayout showHeader={false} showFooter={false}><TrainingPost/></AppLayout>}/>
                                <Route path={routes.train}
                                       element={<AppLayout showHeader={theme.custom.isWhiteLabelled} showFooter={false}><Train/></AppLayout>}/>
                                <Route path={routes.purchase} element={<AppLayout showFooter={false} showHeader={false}><PurchaseFlow/></AppLayout>}/>
                                <Route path={routes.accountManagement} element={<AppLayout showFooter={false} showHeader={true} headerMode={"AccountPage"}><AccountManagementPage/></AppLayout>}/>
                                <Route path={routes.accountSubscriptions} element={<AppLayout showFooter={false} showHeader={true} headerMode={"AccountSubscriptions"}><AccountSubscriptions/></AppLayout>}/>
                                <Route path={routes.subscribe} element={<SubscribeRouteWrapper/>}/>
                                <Route path={routes.orderCreated} element={<AppLayout showFooter={true}
                                                                                      showHeader={true}><OrderCreated/></AppLayout>}/>
                                <Route path={routes.orderSubmitted} element={<AppLayout showFooter={true}
                                                                                        showHeader={true}><OrderSubmitted/></AppLayout>}/>
                            </>
                        }
                        {profile && !userHasAccessToApp && (
                            <Route path={routes.home}
                                   element={<AppLayout showHeader={true}><PermissionRequired/></AppLayout>}/>
                        )}
                    </Route>
                    <Route path={routes.appSignIn}
                           element={<AppLayout showHeader={false} showFooter={false}><AppLogin/></AppLayout>}/>
                    <Route path={routes.referrer}
                           element={<AppLayout showHeader={false} showFooter={false}><PartnerReferred/></AppLayout>}/>
                    <Route path={routes.whiteLabel}
                           element={<AppLayout showHeader={false} showFooter={false}><WhiteLabelled/></AppLayout>}/>
                    <Route path={routes.all} element={<Navigate to={routes.home} replace/>}/>
                </Route>
            </Routes>
        </div>
    );
}

const SubscribeRouteWrapper = (): React.ReactElement => {
const { plan, step } = useParams();
    return (<AppLayout showFooter={false} hideBottomTabs={true} showHeader={true} plan={plan}><StripeProvider options={{...stripeOptions }}><Subscribe plan={plan || "monthly"} step={step}/></StripeProvider></AppLayout>)
}


interface AppLayoutProps {
    children: JSX.Element
    showFooter?: Boolean
    showHeader: Boolean
    headerTitle?: String
    hideBottomTabs?: Boolean
    plan?: string
    headerMode?: HeaderMode
}

function AppLayout(props: AppLayoutProps) {
    const showFooter = !!props.showFooter ? props.showFooter : true
    const theme = useTheme()
    // const plan = props.plan === undefined ? undefined : props.plan.charAt(0).toUpperCase() + props.plan.slice(1)

    const location = useLocation();
    // const userMenu: IUserMenu[] = useMemo(() => [
    //     { title: 'Contact Us', handler: () => {
    //             window.open(Links.customerPortal, '_blank')
    //         }
    //     },
    //     { title: 'My Account', handler: async () => {
    //             navigate(routes.accountManagement);
    //         } },
    //     { title: 'Log out', handler: async () => {
    //         await signOut();
    //         dispatch(userLogout());
    //         navigate(routes.home);
    //     } },

    // ], [navigate, dispatch]);

    useEffect(()=> {
        console.log("attempted: ", getItem("attemptedPagePath"))
        console.log("layout path: ", document.location.pathname)
    },[])

    useEffect(() => window.scrollTo({top: 0}), [location.pathname]);

    useEffect(() => {
        document.body.style.backgroundColor = "#E2E2E2"
    },[theme])

    return (
        <ViaContainer className={`apply-${theme.custom.name}-styles`}>
            {!props.hideBottomTabs && theme.custom.isWhiteLabelled && !isAccountsSection(props.headerMode) && <BottomAppTabs/>}
            {/* {showHeader &&
                <Header headerMode={props.headerMode} plan={plan} title={plan !== undefined ? "Pro Membership: " + plan : theme.custom.headerBrandTitleText} userMenu={userMenu} />} */}
            <StyledMain className="app-content">
                {props.children}
            </StyledMain>
            {showFooter && !theme.custom.isWhiteLabelled && <Footer />}
        </ViaContainer>
    )

}
