import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Snackbar,
    styled,
    Typography,
    useTheme
} from "@mui/material";
import {CheckCircle, HelpOutlineOutlined, RadioButtonUnchecked} from "@mui/icons-material";
import {BasicModal, ButtonType} from "../../../shared/Modal";
import {VideoInstructions} from "../../../shared/VideoInstructions";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {useAppSelector} from "../../../redux/hooks";
import {EditTextArea, TextBoxItem} from "../../../shared/text-field";
import {FeedbackUploadZone} from "../../media/FeedbackUploadZone";
import {MediaUploadResponse, MediaUploadStatus} from "../../media/mediaSlice";
import {RootState} from "../../../redux/store";
import {Links} from "../../../constants/links";
import useQueryEditReasons from "../../../hooks/useQueryEditReasons";
import {useQuery} from "../../../hooks/useQuery";
import {update} from "../../../constants/urlParameters";
import {ICommonStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {FeedbackGuidelines} from "../../../views/feedback/components/FeedbackGuidelines";

export interface ICompleteUploadStepArgs {
    otherPlayersExist: string;
    otherPlayersComment: string;
}

interface IUploadVideoStepProps extends ICommonStepProps {
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    uploadVideoPercentage: number | null;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    abortBackgroundUploadingCB: () => void;
    videoPreviewUrl: string;
    setVideoPreviewUrl: (value: string) => void;
    isMonthlyDrawSelected: boolean;
    onBack: () => void;
    onCompleteUploadVideoStep: ({ otherPlayersExist, otherPlayersComment }: ICompleteUploadStepArgs) => void;
    productSku?: string;
}

const StyledNote = styled(Typography)(() => ({
    marginTop: "20px",
    fontSize: "12px",
    fontWeight: "400",
}));
const TitleTypography = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "700",
}));
const StyledHelpOutlineOutlined = styled(HelpOutlineOutlined)(({theme}) => ({
    color: theme.palette.text.primary,
}));
const StyledSnackBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.uploadBoxColor : "#162121",
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: "8px",
    gap: "20px"
}));
const StyledSnackButton = styled(Button)(() => ({
    padding: "3px",
    minWidth: "52px",
    maxHeight: "30px",
    borderRadius: "14px"
}));
const identificationError = {
    identificationReason: "We can’t identify you in your video. Please provide more detailed information about which player you are.",
    empty: "You need to tell the coach which player you are"
}
export const UploadVideoStep = (
    {
        uploadVideoStepData,
        uploadVideoPercentage,
        uploadVideoInBackgroundCB,
        abortBackgroundUploadingCB,
        videoPreviewUrl,
        setVideoPreviewUrl,
        isMonthlyDrawSelected,
        onBack,
        onCompleteUploadVideoStep,
        onCancelUpdateStep,
        productSku
    }: IUploadVideoStepProps
) => {
    const theme = useTheme()
    const queryUpdate = useQuery().get(update);
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse)
    const [has] = useQueryEditReasons();
    // otherPlayers
    const [otherPlayersExist, setOtherPlayersExist] = useState<string>("Yes");
    const [otherPlayersComment, setOtherPlayersComment] = useState<string>("");
    const [editingOtherPlayersComment, setEditingOtherPlayersComment] = useState<boolean>(true);
    const [otherPlayersError, setOtherPlayersError] = useState<string>("");
    // component related states
    const [backgroundHintShown, setBackgroundHintShown] = useState<boolean>(false);
    const identificationCommentFilled = !editingOtherPlayersComment && (otherPlayersExist.includes("No") || (otherPlayersExist.includes("Yes") && otherPlayersComment.length));

    useEffect(() => {
        Analytics.uploadVideoView();
        if (uploadVideoStepData) {
            setOtherPlayersExist(uploadVideoStepData.otherPlayersExist);
            setOtherPlayersComment(uploadVideoStepData.otherPlayersComment);
        }
    }, [uploadVideoStepData]);
    useEffect(() => {
        if (uploadStatus === MediaUploadStatus.Posting) {
            setBackgroundHintShown(true);
        }
    }, [uploadStatus]);
    useEffect(() => {
        if (has.identifyingCommentReason && !queryUpdate) {
            setEditingOtherPlayersComment(true);
        }
    }, [has.identifyingCommentReason, queryUpdate]);
    useEffect(() => {
        if (has.identifyingCommentReason && otherPlayersExist.includes("Yes") && !otherPlayersComment.length) {
            setOtherPlayersError(identificationError.identificationReason);
        }
    }, [has.identifyingCommentReason, otherPlayersExist, otherPlayersComment, setOtherPlayersError]);
    const onComplete = () => onCompleteUploadVideoStep({otherPlayersExist, otherPlayersComment});
    const onChange = useCallback(() => setOtherPlayersError(""), [setOtherPlayersError])
    const onOtherPlayersExistChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOtherPlayersExist(event.target.value)
        if (event.target.value.includes("Yes")){
            setEditingOtherPlayersComment(true);
        } else {
            setEditingOtherPlayersComment(false);
            setOtherPlayersComment("");
        }
    }
    const onSaveOtherPlayersComment = (value?: string) => {
        if (value?.length && !value?.trim().length) {
            setOtherPlayersError(identificationError.empty);
            return;
        }
        setEditingOtherPlayersComment(!editingOtherPlayersComment);
        if (otherPlayersError) {
            setOtherPlayersError("");
        }
        if (value) {
            setOtherPlayersComment(value.trim());
        }
    }
    const onDiscardOtherPlayersComment = () => {
        if (otherPlayersError) {
            setOtherPlayersError("");
        }
        setEditingOtherPlayersComment(false);
    }

    return (
        <ScreenLayout
            title="Upload Video"
            screenInfo={
                <BasicModal
                    buttonContent={<Typography color='text.primary' sx={{typography: 'caption', fontWeight: 500}}>Video Guidelines</Typography>}
                    buttonProps={{
                        variant: "text",
                        color:"secondary",
                        endIcon: <StyledHelpOutlineOutlined />
                    }}
                    paddingOverride={theme.custom.isWhiteLabelled ? "0 0 16px 0" : ""}
                >
                    {theme.custom.isWhiteLabelled ?
                        <FeedbackGuidelines/>
                        :
                        <VideoInstructions/>
                    }
                </BasicModal>
            }
            ctaDisabled={
                !identificationCommentFilled ||
                !!otherPlayersError ||
                (!uploadResponse && has.noIssues) ||
                (has.videoReason && !uploadResponse)
            }
            ctaTitle={has.noIssues && !queryUpdate ? "Player Information" : "Save"}
            onBack={has.noIssues ? onBack : undefined}
            hideBackButton={theme.custom.isWhiteLabelled}
            onAction={onComplete}
            onCancel={queryUpdate ? onCancelUpdateStep : undefined}
            contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? theme.custom.mainBackgroundColor : undefined}
        >
            <>
                <FeedbackUploadZone
                    showControls={!has.noIssues && !has.videoReason}
                    showReplaceVideo={has.noIssues || has.videoReason}
                    videoIssueReason={has.videoQualityReason ? "Video quality is too low. Please upload a higher quality video." : ""}
                    uploadVideoPercentage={uploadVideoPercentage}
                    uploadVideoInBackgroundCB={uploadVideoInBackgroundCB}
                    abortBackgroundUploadingCB={abortBackgroundUploadingCB}
                    previewUrl={videoPreviewUrl}
                    updatePreviewUrl={setVideoPreviewUrl}
                    isMonthlyDrawSelected={isMonthlyDrawSelected}
                    productSku={productSku || undefined}
                />
                { theme.custom.isWhiteLabelled ?
                    <StyledNote>
                        Make sure you read the&nbsp;
                        <BasicModal
                            buttonType={ButtonType.Link}
                            buttonContent={<Typography
                                color='text.primary'
                                sx={{typography: 'caption', fontWeight: 500, cursor: 'pointer'}}
                            >Video Guidelines</Typography>}
                            buttonProps={{
                                variant: "text",
                                color:"secondary",
                            }}
                            isInlineLink={true}
                        >
                            {theme.custom.isWhiteLabelled ?
                                <FeedbackGuidelines/>
                                :
                                <VideoInstructions/>
                            }
                        </BasicModal>
                        &nbsp;before uploading your video.
                    </StyledNote>
                    :
                    <StyledNote>
                        Make sure you have permission from everyone in the video. Learn more in our <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms</Link>.
                    </StyledNote>
                }
                <Divider sx={{m: "40px 0"}}/>
                <TitleTypography>Are there other players in the video?</TitleTypography>
                <RadioGroup
                    name="radio-buttons-group"
                    row
                    sx={{p: "10px 0"}}
                    value={otherPlayersExist}
                    onChange={onOtherPlayersExistChange}
                >
                    <FormControlLabel
                        value="Yes"
                        label="Yes"
                        control={
                            <Radio
                                icon={<RadioButtonUnchecked color="secondary"/>}
                                checkedIcon={<CheckCircle color="secondary"/>}
                            />
                        }
                    />
                    <FormControlLabel
                        value="No"
                        label="No"
                        disabled={has.identifyingCommentReason}
                        control={
                            <Radio
                                icon={<RadioButtonUnchecked color="secondary"/>}
                                checkedIcon={<CheckCircle color="secondary"/>}
                            />
                        }
                    />
                </RadioGroup>
                {
                    otherPlayersExist.includes('Yes') && (
                        <>
                            <StyledNote sx={{mb: "20px"}}>Please specify the player’s shirt number and shirt colour.</StyledNote>
                            {
                                editingOtherPlayersComment ? (
                                    <EditTextArea
                                        defaultValue={otherPlayersComment}
                                        placeholderValue="E.g. number 11, blue shirt."
                                        errorText={otherPlayersError}
                                        onChange={onChange}
                                        onSave={onSaveOtherPlayersComment}
                                        onDiscard={onDiscardOtherPlayersComment}
                                    />
                                ) :
                                (
                                    <TextBoxItem
                                        item={{id: 0, value: otherPlayersComment, isEditing: editingOtherPlayersComment}}
                                        onChange={onChange}
                                        onEdit={(id: number, value?: string) => onSaveOtherPlayersComment(value)}
                                    />
                                )
                            }
                        </>
                    )
                }
                <Snackbar
                    open={backgroundHintShown}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    sx={{ bottom: { xs: 100, sm: 100 } }}
                >
                    <StyledSnackBox>
                        <Typography>We'll keep uploading your video in the background. Feel free to continue.</Typography>
                        <StyledSnackButton variant="outlined" color="secondary" size="small" onClick={() => setBackgroundHintShown(false)}>
                            Ok
                        </StyledSnackButton>
                    </StyledSnackBox>
                </Snackbar>
            </>
        </ScreenLayout>
    );
}
