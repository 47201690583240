
import React, {useCallback, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {ScreenLayout} from "../../shared/ScreenLayout";
import {Analytics} from "../../lib/analytics";
import {FeedbackGuidelines} from "./components/FeedbackGuidelines";
import {useTheme} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {SoldOut} from "./components/SoldOut";
import {categoriesServiceApi} from "../../services/categories-service";
import {checkIsAnonymousUser, setCurrentSiteSection} from "../../util/storage";

export const FeedbackHome = (): React.ReactElement => {

    const loading = false
    const theme = useTheme();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [showFeedbackWall, setShowFeedbackWall] = useState<boolean>(false);

    const {isLoading: academiesLoading, data: academies, isFetching: academiesFetching} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true });
    const getAvailableAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft > 0) ?? [], [academies]);

    const cancelPurchase = queryParams.get('cancelPurchase') === "true";
    const [showSubWall, setShowSubWall] = useState<boolean>(cancelPurchase)

    const CFPrice = getAvailableAcademies().length ? " FOR " + getAvailableAcademies()[0].currency_symbol + getAvailableAcademies()[0].price : ""


    // const queryEditResultId = useQuery().get(editResultId);
    // const {data: academies, isFetching: academiesFetching} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    // const getAvailableAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft > 0) ?? [], [academies]);
    const { pathname, hash, key } = useLocation();
    useEffect(() => {
        Analytics.feedbackView();
        setCurrentSiteSection("Feedback")
      }, [pathname, hash, key]); // do this on route change

    const handleFeedbackWallAction = () => {
        if (checkIsAnonymousUser()) {
            setShowSubWall(true);
        } else {
            setShowFeedbackWall(true);
        }
    };

    return (
        <>
            <Box sx={{display: loading ? "none" : "flex", flex: 1}}>
                <ScreenLayout
                    title={""}
                    ctaTitle={"GET STARTED"}
                    ctaDisabled={academiesLoading || academiesFetching || !academies?.length}
                    displayCta={!(showFeedbackWall || showSubWall)}
                    ctaUnderSection={<>
                        { academiesLoading || academiesFetching || !academies?.length ?
                            null
                            :
                            !getAvailableAcademies().length && <SoldOut/>
                        }
                    </>}
                    onAction={checkIsAnonymousUser() ? ()=>setShowSubWall(true):  () => setShowFeedbackWall(true)}
                    hideSubWall={()=>setShowSubWall(false)}
                    reloadSignIn={ async ()=> {
                        setShowSubWall(false)
                        setTimeout(()=> setShowSubWall(true), 0.001 )
                    }}
                    contentMarginTop={"0"}
                    contentMarginBottom={"0"}
                    contentPadding={"0"}
                    headerMarginTop={"0"}
                    headerPadding={"0"}
                    contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? "#FFFFFF" : undefined}
                    displaySubGateway={showSubWall}
                    displayFeedbackGateway={showFeedbackWall}
                    hideFeedbackWall={() => setShowFeedbackWall(false)}
                >
                    <>
                        <FeedbackGuidelines cfPrice={CFPrice} setShowFeedbackWall={handleFeedbackWallAction}/>
                        
                    </>
                </ScreenLayout>
            </Box>
        </>
    )
}