import React, {useCallback, useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
// import {Close, InfoOutlined} from '@mui/icons-material';
import {useQuery} from "../../../hooks/useQuery";
// import {coachFeedbackServiceApi} from "../../../services/coach-feedback-service";
import {categoriesServiceApi, } from "../../../services/categories-service";
import {IAcademy} from "../../../types/categories";
import {editResultId} from "../../../constants/urlParameters";
import {getCurrencySymbolWithCode} from "../../../lib/utils";
// import {Analytics} from "../../../lib/analytics";
// import {findCoupon} from "../../../lib/utils";
// import {clearCoupon, getCouponCode, getDefaultClubName, setCoupon, setLuckyDrawClubName} from "../../../util/storage";

interface IGettingStartedStepProps {
    onCompleteGettingStartedStep: (selectedItem: string) => void;
    whiteLabelledBy: string;
    setWhiteLabelStepOffset: (whiteLabelStepOffset: number) => void;
    whiteLabelStepOffset: number;
    productSku?: string;
}


export const luckyDraw: IAcademy = { id: "none", sku: "SKU_LUCKY", avatar: "/misc/coupon.svg", name: "Enter Monthly Draw", category: 99, price: "Free", price_currency: "GBP", currency_symbol: getCurrencySymbolWithCode("GBP"), ticketsLeft: 1 };
// const maxAmountTicketsLeftError = 10;

export const AutoFillGettingStartedStep = ({onCompleteGettingStartedStep, whiteLabelledBy, setWhiteLabelStepOffset, whiteLabelStepOffset, productSku}: IGettingStartedStepProps) => {
    // const querySelected = useQuery().get(selected);
    const queryEditResultId = useQuery().get(editResultId);
    // const queryUpdate = useQuery().get(update);
    // const couponCode = getCouponCode()
    // const [couponEntered, setCouponEntered] = useState<string>(couponCode)
    // const {isLoading: isMonthlyDrawDataLoading, data: monthlyDrawData} = coachFeedbackServiceApi.useGetOpenBallotsQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    // const {isLoading: isSubmittedMonthlyDrawLoading, data: submittedMonthlyDraw} = coachFeedbackServiceApi.useGetSubmittedBallotQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    const {data: academies, isFetching: academiesFetching} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    // const {currentData: coupons, isFetching: couponsFetching} = couponCategoriesServiceApi.useGetCategoriesQuery(couponEntered, {refetchOnMountOrArgChange: couponEntered.length >= minCouponLength || couponEntered === "" , skip: couponEntered.length < minCouponLength });
    // const [showLuckyDrawRules, setShowLuckyDrawRules] = useState<boolean>(false);
    // const [monthlyDrawOnly, setMonthlyDrawOnly] = useState<boolean>(false);
    // const [refreshNoticeShown, setRefreshNoticeShown] = useState<boolean>(false);
    const getAvailableAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft > 0) ?? [], [academies]);
    // const getNotAvailableAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft === 0) ?? [], [academies]);

    // const [buyButtonSelected, setBuyButtonSelected] = useState<boolean>(true);
    // const [delayingCta, setDelayingCta] = useState<boolean>(false);
    // const couponSuccess = couponEntered !== "" && (coupons ? coupons.length > 0 : false)
    let defaultClubName = whiteLabelledBy
    console.log("whiteLabelledBy", whiteLabelledBy)
    let defaultClubSku = ""
    getAvailableAcademies().forEach(academy => {
        if(academy.name === defaultClubName){
            defaultClubSku = academy.sku
        }
    })

    const [selectedItem, setSelectedItem] = useState<string>("");

    // useEffect(() => {
    //     Analytics.purchaseView();
    // }, []);


    useEffect(() => {
        if (academiesFetching || !selectedItem || selectedItem === luckyDraw.sku) {
            return;
        }
        if (!getAvailableAcademies().find(academy => academy.sku === selectedItem)) {
            setSelectedItem("");
        }
    }, [selectedItem, academiesFetching, getAvailableAcademies])


    // const displayTicketsAmount = (ticketsLeft: number) => ticketsLeft <= maxAmountTicketsLeftError;
    //
    // const loading = academiesLoading;


    useEffect(()=>{
        if(whiteLabelledBy !== undefined) {
            if (productSku) {
                console.log("SELECTED SKU:", productSku)
                setSelectedItem(productSku)
                onCompleteGettingStartedStep(productSku)
                setWhiteLabelStepOffset(whiteLabelStepOffset + 1)
            } else {
                const academies = getAvailableAcademies()
                for(let i=0;i<academies.length;i++) {
                    if(academies[i].name === whiteLabelledBy){
                        console.log("SELECTED SKU:", academies[i].sku)
                        setSelectedItem(academies[i].sku)
                        onCompleteGettingStartedStep(academies[i].sku)
                        setWhiteLabelStepOffset(whiteLabelStepOffset + 1)
                    }
                }
            }
        }
    },[defaultClubSku, setSelectedItem, onCompleteGettingStartedStep, whiteLabelStepOffset, setWhiteLabelStepOffset, whiteLabelledBy, getAvailableAcademies, productSku])

    return (
            <CircularProgress sx={{m: "0 auto"}} />
    );
}
