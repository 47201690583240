import React, {useState, useEffect, useRef} from 'react'
import {Box, styled, Typography} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import {Analytics} from "../../../lib/analytics";

import {useTheme} from "@mui/material";
import { Button, Grid, IconButton } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {usePurchaseNavigate} from "../../../hooks/usePurchaseNavigate";
import {checkIsAnonymousUser} from "../../../util/storage";
import {selected} from "../../../constants/urlParameters";

const testimonialData = [
    {
        title: "What players and parents say",
        quote: "We've had an excellent experience with Coach Feedback. The coach's knowledge has been really helpful and we appreciated the opportunity of having an academy coach give us this feedback, usually something my son wouldn’t have access to. It’s a very professional product and we will continue to use it.",
        author: "- Garry, father, UK"
    },
    {
        title: "What players and parents say",
        quote: "My son just got his Coach Feedback and he is blown away. So am I, actually. Just to watch his face and see the enthusiasm.",
        author: "- Marc, father, UK"
    },
    {
        title: "What players and parents say",
        quote: "That feedback was unbelievable. Just the simple things the coach picked up on were spot on and so many positive comments and suggestions from him.",
        author: "- Alex, father, UK"
    },
    {
        title: "What players and parents say",
        quote: "The coach pointed out little things that are vital in a game and made a big difference to my performance.",
        author: "- Paul, player, UK"
    },
    {
        title: "What players and parents say",
        quote: "Before I did this, I knew I needed to work on some things but I didn’t know what. I’m happy that I finally have the tools I need to improve.",
        author: "- Cassidy, player, Canada"
    }
];

const StyledGuidelineText = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 0 16px 0",
    display: 'inline',
}));

const StyledShowMoreLessText = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "400",
    color: "blue", 
    textDecoration: "underline",
    cursor: "pointer",
    display: 'inline',
}));

const StyledFAQItemBox = styled(Box)(() => ({
    marginTop: "16px",
    borderBottom: "0.5px solid #B3BDC8",
}));

const StyledFAQQuestionContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
    }

}))

const StyledFAQQuestion = styled(StyledGuidelineText)(() => ({
    flexGrow: 1,
    padding: "8px 0"
}));

const WhiteBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? "#FFFFFF" : "#000000",
    fontSize: "16px",
    fontWeight: "400",
    color: theme.custom.darkerPrimaryColor,
    padding: "16px",
    '& p': {
        color: theme.custom.darkerPrimaryColor + " !important",
    },
    '& .MuiSvgIcon-root': {
        color: theme.custom.darkerPrimaryColor + " !important",
    }
}));

const LightGreyBox = styled(WhiteBox)(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? "#EFEFEF" : "#121212"
}))

const DarkBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.darkerPrimaryColor,
    fontSize: "16px",
    color: "#FFFFFF !important",
    fontWeight: "400",
    padding: "16px",
    '& p': {
        color: "#FFFFFF !important",
    },
    '& .MuiSvgIcon-root': {
        color: "#FFFFFF !important",
    }
}));

const StyledTitle = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "500",
    padding: "0 0 16px 0",
}));

const StyledHeadline = styled(Typography)(() => ({
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: "8px",
    marginBottom: "16px",
}));

const HiddenFAQTypography = styled(Box)(() => ({
    paddingLeft: "16px",
    overflow: 'hidden',
    maxHeight: 0, // Start with 0 max-height
    // opacity: 0,
    transition: 'all 0.3s ease-out',
    '&.visible': {
        maxHeight: '800px', // Adjust based on your content needs
        // opacity: 1,
    }
}));

interface FeedbackGuidelinesProps {
    cfPrice?: string
    setShowFeedbackWall?: React.Dispatch<React.SetStateAction<boolean>>
}

export const FeedbackGuidelines = (props: FeedbackGuidelinesProps): React.ReactElement => {
    const [visibleFAQs, setVisibleFAQs] = useState<number[]>([])
    const [showMore, setShowMore] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [startPurchase] = usePurchaseNavigate();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const startInterval = () => {
            intervalRef.current = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData.length);
            }, 5000);
        };

        startInterval();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleDotClick = (index: number) => {
        setCurrentSlide(index);
    
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    
        intervalRef.current = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData.length);
        }, 5000);
    };

    const handleFAQClick = (itemNumber: number): void => {
        if(visibleFAQs.includes(itemNumber)){
            setVisibleFAQs(visibleFAQs.filter((i:number) => i !== itemNumber ))
        }else
            setVisibleFAQs(visibleFAQs.concat([itemNumber]))
    }

    const faqIsVisible = (itemNumber: number): boolean => {
        return visibleFAQs.includes(itemNumber)
    }

    const handleToggleShowMore = () => {
        setShowMore(!showMore);
    };
    
    useEffect(() => {
        Analytics.videoGuidelinesView();
    },[])

    const theme = useTheme();
    const academyName = theme.custom.isWhiteLabelled ? theme.custom.academyCommonName : "Academy"
    const supportEmail = theme.custom.isWhiteLabelled ? theme.custom.wl.supportEmail : "hello@viaacademy.com"
    const sectionRef = useRef<HTMLDivElement>(null)

    return(
        <>
            { !!theme.custom.coachFeedbackPromoPoster ?
                <Box
                    onClick={()=>{
                        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    sx={{
                        "&:hover":{
                            cursor: "pointer"
                        }
                    }}
                >
                    <img
                        style={{
                            maxWidth: "100%"
                        }}
                        src={theme.custom.coachFeedbackPromoPoster}
                        alt={theme.custom.academyCommonName + " Feedback Poster"}
                    />
                </Box>
                :
                <WhiteBox>
                    <StyledTitle color={"secondary"} className="use-header-font">FEEDBACK</StyledTitle>
                    <StyledGuidelineText>Personalised feedback and analysis on your videos
                        from {academyName} coaches!</StyledGuidelineText>
                    <video
                        controls
                        playsInline
                        src={theme.custom.coachFeedbackVideoSampleLink}
                        poster={theme.custom.coachFeedbackVideoSampleThumbnailLink}
                        style={{
                            width: "100%",
                            borderRadius: "4px",
                            // margin: "3%",
                        }}
                    />
                </WhiteBox>
            }
            <WhiteBox sx={{ padding: "16px", marginBottom: "8px" }}>
                <Typography 
                    sx={{ 
                        fontSize: "16px", 
                        fontWeight: "400", 
                        color: theme.custom.darkerPrimaryColor 
                    }}
                >
                    Our {academyName} Coaches are here to help you unlock your potential. We’ll give you the same feedback & analysis as our Academy players get.
                </Typography>
            </WhiteBox>
            <DarkBox>
                {!!theme.custom.coachFeedbackPromoPoster ?
                    <>
                    <StyledTitle ref={sectionRef} color={"white"} className="use-header-font">What's Academy Feedback?</StyledTitle>
                    <video
                        controls
                        playsInline
                        src={theme.custom.coachFeedbackVideoSampleLink}
                        poster={theme.custom.coachFeedbackVideoSampleThumbnailLink}
                        style={{
                            width: "100%",
                            borderRadius: "4px",
                            // margin: "3%",
                            paddingBottom: "10px"
                        }}
                    />
                    </>
                    : <StyledHeadline>What’s Academy Feedback?</StyledHeadline>
                }
                <StyledGuidelineText>
                Send your match/highlights videos to our {academyName} coaches and get personalised analysis and feedback.
                {!showMore && (
                    <StyledShowMoreLessText onClick={handleToggleShowMore} sx={{ marginLeft: "4px" }}>
                         Show More
                    </StyledShowMoreLessText>
                )}
            </StyledGuidelineText>
            {showMore && (
                <Box>
                    <StyledGuidelineText>
                        <br />
                        This is your opportunity to develop faster with the help of {academyName} coaches.
                        <br /><br />
                        We’ll help unleash your potential by watching your videos and highlighting your strengths and where you need to focus.
                        <br /><br />
                        Send us your whole match video or highlights and see how your performance improves.
                        <br /><br />
                    </StyledGuidelineText>
                    <StyledShowMoreLessText onClick={handleToggleShowMore}>
                    Show Less
                    </StyledShowMoreLessText>
                </Box>
            )}
            <Box sx={{ marginTop: "24px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: theme.custom.matchAnalysisBackground,
                                borderRadius: "8px",
                                padding: "16px",
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h6" fontWeight="bold" sx={{marginTop: "2px", color: theme.custom.darkerPrimaryColor}}>
                                Match Analysis
                            </Typography>
                            <Typography variant="h4" sx={{marginTop: "4px", color: theme.custom.darkerPrimaryColor}}>
                                £99.00
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: "8px", color: theme.custom.darkerPrimaryColor}}>
                                You send
                            </Typography>
                            <Typography variant="subtitle1" sx={{color: theme.custom.darkerPrimaryColor}}>
                                Your whole match
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: "16px", color: theme.custom.darkerPrimaryColor, marginBottom: "12px" }}>
                                You receive
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor, marginBottom: "12px" }}>
                                Highlight reel of your key moments
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor, marginBottom: "12px" }}>
                                Analysis and feedback on your individual performance
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor, marginBottom: "12px" }}>
                                Feedback video from {academyName} coach
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor }}>
                                Player analysis report - 
                            </Typography>
                            <Typography
                                component="a"
                                href="/step-out-demo/player-analysis-example.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    textDecoration: "underline",
                                    color: theme.custom.darkerPrimaryColor,
                                    cursor: "pointer",
                                    marginTop: "-8px"
                                }}
                            >
                                See Example
                            </Typography>
                            <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                    marginTop: "16px",
                                    background: "#B52C23",
                                    color: "#FFFFFF",
                                    '&:hover': {
                                        backgroundColor: theme.custom.darkerPrimaryColor
                                    }
                                }}
                                onClick={() => {
                                    if (checkIsAnonymousUser()) {
                                        props.setShowFeedbackWall?.(true);
                                    } else {
                                        startPurchase({[selected]: `MA-${theme.custom.academyCommonShortName}`});
                                    }
                                }}
                            >
                                BUY NOW
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: theme.custom.highlightAnalysisBackground,
                                borderRadius: "8px",
                                padding: "16px",
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h6" fontWeight="bold" sx={{color: theme.custom.darkerPrimaryColor}}>
                                Highlights Analysis
                            </Typography>
                            <Typography variant="h4" sx={{color: theme.custom.darkerPrimaryColor, marginTop: "8px", marginBottom: "8px"}}>
                                £49.00
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold" sx={{color: theme.custom.darkerPrimaryColor}}>
                                You send
                            </Typography>
                            <Typography variant="subtitle1" sx={{color: theme.custom.darkerPrimaryColor}}>
                                Up to 5 mins of your match/training highlights
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ color: theme.custom.darkerPrimaryColor}}>
                                You receive
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" sx={{color: theme.custom.darkerPrimaryColor}}>
                                Analysis and feedback on your highlights
                                <br />
                                <br />
                                Feedback video from {academyName} coach
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: "16px",
                                    background: "#B52C23",
                                    color: "#FFFFFF",
                                    '&:hover': {
                                        backgroundColor: theme.custom.darkerPrimaryColor
                                    }
                                }}
                                onClick={() => {
                                    if (checkIsAnonymousUser()) {
                                        props.setShowFeedbackWall?.(true);
                                    } else {
                                        startPurchase({[selected]: `CF-${theme.custom.academyCommonShortName}`});
                                    }
                                }}
                            >
                                BUY NOW
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    backgroundColor: theme.custom.testimonialBackground,
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                    color: theme.custom.darkerPrimaryColor,
                    fontSize: "16px",
                    fontWeight: "400",
                    position: "relative",
                    textAlign: 'center',
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "500", color: theme.custom.darkerPrimaryColor }}>
                        {testimonialData[currentSlide].title}
                </Typography>
                <Typography variant="subtitle1" sx={{ marginTop: "12px", color: theme.custom.darkerPrimaryColor }}>
                        {testimonialData[currentSlide].quote}
                </Typography>
                <Typography variant="subtitle1" sx={{ marginTop: "12px", fontWeight: "200", color: theme.custom.darkerPrimaryColor }}>
                    {testimonialData[currentSlide].author}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "16px",
                    }}
                >
                    {testimonialData.map((_, index) => (
                        <IconButton
                            key={index}
                            onClick={() => handleDotClick(index)}
                        >
                            <FiberManualRecordIcon
                                sx={{
                                    fill: index === currentSlide ? theme.custom.darkerPrimaryColor : "#FFFFFF",
                                    fontSize: '12px'
                                }}
                            />
                        </IconButton>
                    ))}
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: theme.custom.whatYouGetBackground,
                    borderRadius: "8px",
                    padding: "16px",
                    textAlign: "left",
                    marginTop: "16px",
                }}
            >
                <Typography variant="h6" fontWeight="bold" sx={{ color: theme.custom.darkerPrimaryColor }}>
                    What you get
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fill: theme.custom.darkerPrimaryColor, marginRight: "8px" }} />
                    <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor }}>
                        Opportunity to be seen by Academies
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fill: theme.custom.darkerPrimaryColor, marginRight: "8px" }} />
                    <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor }}>
                        Analysis directly from the {academyName}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fill: theme.custom.darkerPrimaryColor, marginRight: "8px" }} />
                    <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor }}>
                        Gain a competitive edge
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fill: theme.custom.darkerPrimaryColor, marginRight: "8px" }} />
                    <Typography variant="subtitle1" sx={{ color: theme.custom.darkerPrimaryColor }}>
                        Know where to focus to reach your best
                    </Typography>
                </Box>
            </Box>
            </DarkBox>

            <LightGreyBox>
                <StyledHeadline>FAQs</StyledHeadline>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(1)}>
                        <StyledFAQQuestion>Why should I buy Academy Feedback?</StyledFAQQuestion>
                        {faqIsVisible(1) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(1) ? "visible" : "hidden"}>
                        <p>This is your opportunity to develop faster with the help of {academyName} coaches.</p>
                        <p>Send us your match/training videos and our coaches will give you personalised analysis and feedback to help take your game to the next level.</p>
                        <p>Academy Feedback helps you unleash your potential by highlighting your strengths and where you need to focus, so you can get the most out of your training and perform better in games.</p>
                        <p>This is your chance to be seen by the {academyName} coaches, develop faster, and get one step ahead of the competition.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(2)}>
                        <StyledFAQQuestion>What’s the difference between Match Analysis and Highlights Analysis?</StyledFAQQuestion>
                        {faqIsVisible(2) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(2) ? "visible" : "hidden"}>
                        <p>Match Analysis allows you to send in a whole match video, for example, footage from your team’s VEO camera. Your Feedback video will include analysis and feedback on your own individual performance across the entire match. You’ll also receive a player analysis report which gives you an overview of your performance statistics across the match. Match Analysis costs £99 for one match.</p>
                        <p>Highlights Analysis allows you to send a video of up to 5 minutes showing highlights from your matches or training sessions. Your Feedback video will include analysis and feedback on your highlights. Highlights Analysis costs £49 for one highlights video.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(3)}>
                        <StyledFAQQuestion>Who are the coaches that will analyse my video?</StyledFAQQuestion>
                        {faqIsVisible(3) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(3) ? "visible" : "hidden"}>
                        <p>Your video will be reviewed by a qualified professional coach who is currently employed in an active coaching role at the {academyName}. All of our coaches are specialists in top-level youth football.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(4)}>
                        <StyledFAQQuestion>What are the steps involved?</StyledFAQQuestion>
                        {faqIsVisible(4) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(4) ? "visible" : "hidden"}>
                        <p>Tap the ‘GET STARTED’ button and follow the steps to:</p>
                        <ol>
                            <li>Choose whether you want to upload a whole match or highlights</li>
                            <li>Upload your video</li>
                            <li>Identify yourself in the video (shirt number and colour)</li>
                            <li>Tell the {academyName} coach your name, position and age</li>
                            <li>Tell the {academyName} coach up to 3 things you want to improve on</li>
                            <li>Enter your payment details</li>
                            <li>Review and submit your purchase</li>
                        </ol>
                        <p>Once you’ve submitted your video, the {academyName} coach will watch your video, analyse it, and create a personalised Feedback video for you. In your Feedback video, the coach will highlight your strengths, tell you where you need to focus, and coach you on how to improve drawing on our {academyName} philosophy.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(5)}>
                        <StyledFAQQuestion>What kinds of videos can I submit?</StyledFAQQuestion>
                        {faqIsVisible(5) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(5) ? "visible" : "hidden"}>
                        <p>For Match Analysis, you can upload one video file containing footage of a whole match. For example, footage from your team’s VEO camera. The maximum length is 140 minutes and the maximum file size is 5GB.</p>
                        <p>For Highlights Analysis, you can upload one video file showing your highlights from different matches/training sessions. It can include clips from your matches, training, casual games in the park, practising in your backyard etc. The maximum length is 5 minutes and the maximum file size is 2GB.</p>
                        <p>For both Match Analysis and Highlights Analysis, we support MP4, MOV and HEVC file types. Please make sure you have permission to use any videos you send to us. See our tips below for how to make sure your video is good quality.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(6)}>
                        <StyledFAQQuestion>How can I make sure I submit a good quality video?</StyledFAQQuestion>
                        {faqIsVisible(6) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(6) ? "visible" : "hidden"}>
                        <p>Here are our top tips for uploading good quality videos:</p>
                        <p><strong>VISIBILITY:</strong> Make sure you’re clearly visible in the video.</p>
                        <p><strong>FILE QUALITY:</strong> Try to upload high quality, large-size video files. If you are stitching different clips together, don’t combine portrait and landscape clips as this can make some videos appear very small.</p>
                        <p><strong>IMPROVEMENT POINTS:</strong> When you submit your video, you’ll also tell the coach what you want to improve on. Try to include footage in your video clips that shows you doing the things you want to improve on.</p>
                        <p><strong>PLAYER ID:</strong> If there are other players in the video, you’ll be asked to tell the coach which player you are. Make sure you clearly describe which player you are in each different clip that you submit. It is important that you at least specify your player number and shirt colour.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(7)}>
                        <StyledFAQQuestion>When will I get my feedback?</StyledFAQQuestion>
                        {faqIsVisible(7) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(7) ? "visible" : "hidden"}>
                        <p>Your feedback video will usually be ready within 10 days of purchase. If there is an issue with the video that you’ve submitted, we may ask you to re-submit your video or submit a new one. In this case, your feedback video will be ready within 10 days after resubmission.</p>
                        <p>We will send an email to your account email address when your Feedback video is ready. Please note this may be different from the purchaser email address that you entered upon purchasing the product. Your account email address is displayed in your account settings in the Virtual Academy (tap the profile icon in the top right corner).</p>
                        <p>Your Feedback video will appear in the Feedback tab of the Virtual Academy under the heading ‘Received’. Please make sure you are logged in to the Virtual Academy to see it. You will be able to download your Feedback video for offline viewing.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(8)}>
                        <StyledFAQQuestion>What should I do if Academy Feedback is sold out?</StyledFAQQuestion>
                        {faqIsVisible(8) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(8) ? "visible" : "hidden"}>
                        <p>Analysing your videos and giving you personalised feedback takes time, and the {academyName} coaches only have a limited number of time slots to allocate.</p>
                        <p>If all of our coaches’ available slots are full, you will see a ‘sold out’ message and we will temporarily suspend the ability to purchase Academy Feedback until the coaches have availability again.</p>
                        <p>If Academy Feedback is sold out, please check back tomorrow as coach availability is refreshed daily.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(9)}>
                        <StyledFAQQuestion>I’m purchasing Academy Feedback for my child, what do I need to do?</StyledFAQQuestion>
                        {faqIsVisible(9) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(9) ? "visible" : "hidden"}>
                        <p>Players and parents complete the same steps when purchasing Academy Feedback.</p>
                        <p>If you are purchasing Academy Feedback on behalf of your child, then whenever you are asked to enter information about the player (e.g. player identification, player name, age and position, and player improvement points), you should enter your child’s information.</p>
                        <p>When you are entering your payment details, please make sure you enter your own email address, not your child’s. All notifications about your Academy Feedback order will be sent to your account email address.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>

                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(10)}>
                        <StyledFAQQuestion>I am a coach/club, can you analyse my whole team’s performance?</StyledFAQQuestion>
                        {faqIsVisible(10) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(10) ? "visible" : "hidden"}>
                        <p>If you are a coach or a club and want professional Academy feedback on your team’s whole match or multiple matches, we’d love to hear from you! Please email us at {supportEmail} to start the conversation.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
            </LightGreyBox>
        </>


    )
}