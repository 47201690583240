import React, {useState} from 'react'
import {Box, Button, styled, Typography, useTheme} from "@mui/material";
import {checkIsAnonymousUser} from "../util/storage";
import {AskParent} from "../views/feedback/components/AskParent";
import {usePurchaseNavigate} from "../hooks/usePurchaseNavigate";
import {selected} from "../constants/urlParameters";


const StyledFilledHeader = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    padding: "24px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}));


const StyledTopHeader = styled(Typography)( ()=> ({
    fontSize: "18px",
    fontWeight: "600",
}));

const StyledSubWallBox = styled(Box)(({theme}) => ({
    color: theme.custom.darkerPrimaryColor,
    padding: "24px 24px 0 16px",
}));

const StyledChoice = styled(Box)(({theme}) => ({
    borderRadius: "8px",
    padding: "16px 24px",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    "&:hover": {
        cursor: "pointer"
    },
    "&.selected": {
        borderColor: theme.custom.subscriptionChoiceBorderColor,
        backgroundColor: "#FFFFFF",
    },
    backgroundColor: "#EEEEEE"
}))

const ChoiceText1 = styled(Typography)(()=>({
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "12px",
    textAlign: 'center',
}))

const ChoiceText2 = styled(Typography)(()=>({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: 'center',
}))

const PriceBox = styled(Box)(()=>({
    padding: "8px 0",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: "4px",
    "& .price-label":{
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "32px",
    },
    "& .slashed":{
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "22px",
        color: "#AAAAAA",
        // textDecoration: "line-through",
    },
    "& .slashed:before": {
        position: "absolute",
        content: "''",
        left: 0,
        top: "50%",
        right: 0,
        borderTop: "0.7px solid",
        transform: "rotate(-8deg)",
        borderColor: "red"
    }
}))

const StyledParentButton = styled(Button)(()=>({
    "&.Mui-disabled": {
        backgroundColor: "#999999 !important",
        color: "#FFFFFF !important"
    },
    justifyContent: "center",
    padding: "10px;",
    color: "#FFFFFF",
    textTransform: "uppercase"
}))

// const LegalNote = styled(Typography)(()=>({
//     marginTop: "24px",
//     marginBottom: "8px",
//     fontSize: "14px",
//     fontWeight: 400,
//     lineHeight: "20px",
//     // textAlign: 'center',
// }))

export interface FeedbackWallComponentProps {
    hideFeedbackWall?: () => void
}

export const FeedbackWall = (props:FeedbackWallComponentProps): React.ReactElement => {
    const theme = useTheme()
    const [selectedPlan, setSelectedPlan] = useState<string>("MA")
    const [startPurchase] = usePurchaseNavigate();

    return (
        <Box sx={{display: "flex", flexDirection: "column", zIndex: 1}} className="sticky-footer">
            <StyledFilledHeader>
                <img style={{maxHeight: "32px", maxWidth: !!theme.custom.brandAcademyLogoHorizontal ? undefined : "32px"}}
                     src={!!theme.custom.brandAcademyLogoHorizontal ? theme.custom.brandAcademyLogoHorizontal : theme.custom.brandTitleLogo}
                     alt={(theme.custom.isWhiteLabelled ? theme.custom.academyCommonName || "Via Academy" : "Via Academy") + " Logo"}/>
                <StyledTopHeader>Get Academy Feedback</StyledTopHeader>
            </StyledFilledHeader>
            <StyledSubWallBox>
                <Box
                    sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: "16px"
                    }}
                >
                    <StyledChoice 
                        // selected plan of MA is for full matches
                        onClick={() => setSelectedPlan("MA")} 
                        className={selectedPlan === "MA" ? "selected" : ""}
                    >
                        <ChoiceText1>Match Analysis</ChoiceText1>
                        <PriceBox>
                            <Typography className="price-label">£99</Typography>
                        </PriceBox>
                        <ChoiceText2>Submit a whole match</ChoiceText2>
                    </StyledChoice>
                    <StyledChoice 
                        // selected plan of CF is original coach feedback (highlights)
                        onClick={() => setSelectedPlan("CF")} 
                        className={selectedPlan === "CF" ? "selected" : ""}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <ChoiceText1>Highlights Analysis</ChoiceText1>
                        <PriceBox>
                            <Typography className="price-label">£49</Typography>
                        </PriceBox>
                        <ChoiceText2>Submit up to 5 minutes</ChoiceText2>
                    </StyledChoice>
                </Box>

                {checkIsAnonymousUser() ?
                    <Box sx={{textAlign: "center"}}>
                        <StyledParentButton
                            disabled={true}
                            variant="contained"
                        >Ask your parent/guardian</StyledParentButton>
                        <AskParent topic="training"/>
                    </Box>
                    :
                    <Button
                        variant="contained"
                        sx={{
                            justifyContent: "center",
                            padding: "10px;",
                            color: "#FFFFFF",
                            backgroundColor: theme.custom.newButtonColor
                        }}
                        fullWidth
                        disableElevation={true}
                        onClick={
                            ()=> {
                                startPurchase({[selected]: `${selectedPlan}-${theme.custom.academyCommonShortName}`})
                                console.log(`${selectedPlan}-${theme.custom.academyCommonShortName}`)
                            }
                        }
                    >Continue</Button>
                }
            </StyledSubWallBox>
            <Button sx={{padding: "10px 0"}} variant="text"
                                               onClick={props.hideFeedbackWall}>Not now</Button>
        </Box>
    )

}